/**
 * Return config payslip each position
 *
 * @param {string | Function(row)} key - return value of data.
 * @param {string | Function(index)} title - Title of data.
 * @param {'currency' | '%'} prefix - change prefix value.
 * @param {boolean} extraTime - change prefix value.
 * @param {boolean} logsArray - render multiple rows of log based on key.
 *
 */
export default {
  //Head office config
  'head-office': [
    {
      key: 'muc_luong',
      title: 'Mức lương',
      prefix: 'currency',
    },
    {
      key: 'attendance',
      title: 'Công đi làm thực tế',
    },
    {
      key: 'so_nghi_phep_nam',
      title: 'Số ngày phép',
    },
    {
      key: 'so_ngay_phep_con_lai',
      title: 'Số ngày phép còn lại',
    },
    {
      key: 'so_nghi_phep_khac',
      title: 'Ngày Lễ, Tết, Việc riêng',
    },
    {
      key: 'tong_cong_thang',
      title: 'Tổng công tháng',
    },
    {
      key: 'kpi_deadline',
      title: '% KPI hoàn thành công việc',
      prefix: '%',
    },
    {
      key: 'tong_thu_nhap',
      title: 'Tổng thu nhập',
      prefix: 'currency',
    },
    {
      key: 'tien_phu_cap',
      title: 'Phụ cấp',
      prefix: 'currency',
    },
    {
      key: 'thuong',
      title: 'Thưởng DS/CV',
      prefix: 'currency',
    },
    {
      key: 'phat_cong',
      title: 'Không đủ công',
    },
    {
      key: 'sinh_nhat',
      title: 'Sinh nhật',
      prefix: 'currency',
    },
    {
      key: 'tien_bao_hiem',
      title: 'Trừ đóng BH',
      prefix: 'currency',
    },
    {
      key: 'thu_nhap_thieu_thang_truoc',
      title: 'Thu nhập thiếu tháng trước',
      prefix: 'currency',
    },
    {
      key: 'phat',
      title: 'Phạt',
      prefix: 'currency',
    },
    {
      key: 'tam_ung',
      title: 'Tạm ứng',
      prefix: 'currency',
    },
    {
      key: 'mba',
      title: 'MBA',
    },
    {
      key: 'tru_khac',
      title: 'Trừ khác',
      prefix: 'currency',
    },
    {
      key: 'salary',
      title: 'Lương thực nhận',
      prefix: 'currency',
    },
  ],
  //GDVP config
  gdvp: [
    {
      key: 'doanh_thu_doi_kd',
      title: 'Doanh thu đội KD (1)',
      extraTime: true,
      prefix: 'currency',
    },
    {
      key: 'doanh_thu_qlhv',
      title: 'Doanh thu QLHV (2)',
      extraTime: true,
      prefix: 'currency',
    },
    {
      key: 'chuyen_hp',
      title: 'Chuyển học phí (3)',
      extraTime: true,
      prefix: 'currency',
    },
    {
      key: (row) => row.doanh_thu_doi_kd + row.doanh_thu_qlhv + row.chuyen_hp,
      title: `Tổng doanh thu: (4)=(1)+(2)+(3)`,
      bold: true,
      prefix: 'currency',
    },
    {
      key: 'luong_doi_kd',
      title: 'Lương đội KD (5)',
      extraTime: true,
      prefix: 'currency',
    },
    {
      key: 'luong_doi_qlhv',
      title: 'Lương đội QLHV (6)',
      extraTime: true,
      prefix: 'currency',
    },
    {
      key: (row) => row.luong_doi_kd + row.luong_doi_qlhv,
      title: 'Tổng chi phí lương (7)=(5)+(6)',
      bold: true,
      prefix: 'currency',
    },
    {
      key: 'chi_phi_sp',
      title: 'Chi phí SP (8)',
      extraTime: true,
      prefix: 'currency',
    },
    {
      key: 'phi_backoffice',
      title: `Phí tổng, backoffice 12,5%: (9) = 12,5% * (4)`,
      bold: true,
      prefix: 'currency',
    },
    {
      key: 'doanh_so_tai_diem',
      title: 'Doanh số tại điểm (10)',
      extraTime: true,
      prefix: 'currency',
    },
    {
      key: 'phan_tram_chi_sp_tai_diem',
      title: '% Chi phí tại điểm (11)',
      extraTime: true,
      prefix: '%',
    },
    {
      key: 'chi_nha',
      title: 'Chi nhà (12)',
      extraTime: true,
      prefix: 'currency',
    },
    {
      key: 'chi_khau_hao',
      title: 'Chi khấu hao (13)',
      extraTime: true,
      prefix: 'currency',
    },
    {
      key: 'chi_khac',
      title: 'Chi khác (14)',
      extraTime: true,
      prefix: 'currency',
    },
    {
      key: 'phi_phong_hoc_gv',
      title: 'Phí phòng học của GV (15)',
      extraTime: true,
      prefix: 'currency',
    },
    {
      key: 'thuong_team_lead',
      title: 'Thưởng Team leader (16)',
      extraTime: true,
      prefix: 'currency',
    },
    {
      key: 'loi_nhuan_kd_tai_diem',
      title: `Lợi nhuận đội kinh doanh tại điểm: (17)=(4)-(7)-(8)-(9)-(12)-(13)-(14)+(15)-(16)`,
      extraTime: true,
      prefix: 'currency',
    },
    {
      key: 'wow_1',
      title: 'Số Wow (18)',
      extraTime: true,
    },
    {
      key: 'wow_2',
      title: 'Điểm Wow (19)',
      extraTime: true,
    },
    {
      key: 'thu_nhap_co_tuc',
      title: `Thu nhập cổ tức: (20) = 50% * (17) * % cổ phần của GĐVP`,
      extraTime: true,
      prefix: 'currency',
    },
    {
      key: 'thu_nhap_wow',
      title: `Thu nhập Wow: (21) = 2% * (4) *(19)`,
      extraTime: true,
      prefix: 'currency',
    },
    {
      key: 'thu_nhap_truong_phong_co_so',
      title: 'Thu nhập Trưởng phòng cơ sở ',
      extraTime: true,
      prefix: 'currency',
    },
    {
      key: (row) => row.thu_nhap_co_tuc + row.thu_nhap_wow,
      title: 'Tổng thu nhập',
      extraTime: true,
      prefix: 'currency',
    },
    {
      key: 'loi_nhuan_giu_lai',
      title: `Lợi nhuận còn lại 50%: (23) = 50%*(17)`,
      extraTime: true,
    },
    {
      key: 'cong_no',
      title: 'Công nợ',
      extraTime: true,
      prefix: 'currency',
    },
    {
      key: 'thuong',
      title: 'Thưởng',
      extraTime: true,
      prefix: 'currency',
    },
    {
      key: 'tien_phu_cap',
      title: 'Phụ cấp',
      prefix: 'currency',
    },
    {
      key: 'tam_ung',
      title: 'Tạm ứng',
      extraTime: true,
      prefix: 'currency',
    },
    {
      key: 'phat',
      title: 'Phạt',
      extraTime: true,
      prefix: 'currency',
    },
    {
      key: 'tru_khac',
      title: 'Trừ khác',
      prefix: 'currency',
    },
    {
      key: 'salary',
      title: 'Lương thực nhận',
      extraTime: true,
      prefix: 'currency',
    },
  ],
  //GDKV config
  gdkv: [
    {
      key: 'muc_luong',
      title: 'Mức lương',
      extraTime: true,
      prefix: 'currency',
    },
    {
      key: 'kpi_deadline',
      title: '% KPI hoàn thành công việc phòng ban',
      prefix: '%',
    },
    {
      key: (row) =>
          row.attendance + row.so_nghi_phep_nam + row.so_nghi_phep_khac,
      title: 'Tổng công tháng',
      extraTime: true,
    },
    {
      key: 'loi_nhuan_kd_tai_diem',
      title: 'Lợi nhuận tại điểm',
      extraTime: true,
      prefix: 'currency',
    },
    {
      key: 'loi_tuc',
      title: 'Lợi tức',
      extraTime: true,
      prefix: 'currency',
    },
    {
      key: 'doanh_so_doi_trong_khu_vuc',
      title: 'Doanh số đội trong khu vực',
      extraTime: true,
      prefix: 'currency',
    },
    {
      key: 'wow_2,2',
      title: 'Điểm wow',
      bold: true,
    },
    {
      key: 'kpi',
      title: 'KPI',
      extraTime: true,
      prefix: '%',
    },
    {
      key: 'thuong_kpi',
      title: 'Thưởng KPI',
      bold: true,
      prefix: 'currency',
    },
    {
      key: 'loi_nhuan_vp_con_lai',
      title: 'Lợi nhuận văn phòng còn lại',
      extraTime: true,
      prefix: 'currency',
    },
    {
      key: 'tong_thu_nhap',
      title: 'Tổng thu nhập',
      prefix: 'currency',
      extraTime: true,
    },
    {
      key: 'tien_phu_cap',
      title: 'Phụ cấp',
      prefix: 'currency',
    },
    {
      key: 'thuong',
      title: 'Thưởng',
      extraTime: true,
      prefix: 'currency',
    },
    {
      key: 'sinh_nhat',
      title: 'Sinh nhật',
      extraTime: true,
      prefix: 'currency',
    },
    {
      key: 'thu_nhap_thieu_thang_truoc',
      title: 'Thu nhập thiếu tháng trước',
      extraTime: true,
      prefix: 'currency',
    },
    {
      key: 'tam_ung',
      title: 'Tạm ứng',
      extraTime: true,
      prefix: 'currency',
    },
    {
      key: 'phat',
      title: 'Phạt',
      extraTime: true,
      prefix: 'currency',
    },
    {
      key: 'salary',
      title: 'Lương thực nhận',
      extraTime: true,
      prefix: 'currency',
    },
  ],
  //QLHV config
  qlhv: [
    {
      key: 'muc_luong_thoi_gian',
      title: 'Mức lương thời gian',
      prefix: 'currency',
    },
    {
      key: 'muc_luong_hoan_thanh_cv',
      title: 'Mức lương Hoàn thành CV',
      prefix: 'currency',
    },
    {
      key: 'so_hop_dong_nhan',
      title: 'Số hợp đồng nhân',
    },
    {
      key: 'doanh_so_nhan',
      title: 'Doanh số nhân',
    },
    {
      key: 'attendance',
      title: 'Ngày công tháng',
    },
    {
      key: 'kpi_deadline',
      title: '% KPI hoàn thành công việc',
      prefix: '%',
    },

    {
      key: 'tong_thu_nhap',
      title: 'Tổng thu nhập',
      prefix: 'currency',
    },
    {
      key: 'tien_phu_cap',
      title: 'Phụ cấp',
      prefix: 'currency',
    },
    {
      key: 'thuong',
      title: 'Thưởng',
      prefix: 'currency',
    },
    {
      key: 'thu_nhap_thieu_thang_truoc',
      title: 'Thu nhập thiếu tháng trước',
      prefix: 'currency',
    },
    {
      key: 'phat',
      title: 'Phạt',
      prefix: 'currency',
    },
    {
      key: 'tru_ao_qlhv',
      title: 'Trừ áo QLHV',
      prefix: 'currency',
    },
    {
      key: 'tru_khac',
      title: 'Trừ khác',
      prefix: 'currency',
    },
    {
      key: 'salary',
      title: 'Lương thực nhận',
      prefix: 'currency',
    },
  ],
  //Sale Leader config
  'sale-leader': [
    {
      key: 'revenue_personal',
      title: 'Doanh số cá nhân',
      extraTime: true,
      logsArray: true,
      prefix: 'currency',
    },
    {
      key: 'revenue_sale_expert',
      title: 'Doanh số đội SE',
      extraTime: true,
      logsArray: true,
      prefix: 'currency',
    },
    {
      key: 'revenue_team',
      title: 'Doanh số đội SL',
      extraTime: true,
      logsArray: true,
      prefix: 'currency',
    },
    {
      key: 'doanh_so_slx',
      title: 'Doanh số đội SLx',
      extraTime: true,
      logsArray: true,
      prefix: 'currency',
    },
    {
      key: 'thu_nhap_kinh_doanh',
      title: 'Thu nhập kinh doanh',
      extraTime: true,
      logsArray: true,
      prefix: 'currency',
    },
    {
      key: 'so_hop_dong',
      title: 'Số hợp đồng TVV',
      bold: true,
    },
    {
      key: 'thu_nhap_tvv',
      title: 'Thu nhập TVV',
      extraTime: true,
      logsArray: true,
      prefix: 'currency',
    },
    {
      key: 'kpi_mang',
      title: 'KPI trưởng phòng cơ sở',
      prefix: '%',
      extraTime: true,
    },
    {
      key: 'thu_nhap_truong_phong',
      title: 'Thu nhập trưởng phòng',
      extraTime: true,
      logsArray: true,
      prefix: 'currency',
    },
    {
      key: 'thuong',
      title: 'Thưởng',
      extraTime: true,
      logsArray: true,
      prefix: 'currency',
    },
    {
      key: 'thu_nhap_thieu_thang_truoc',
      title: 'Thu nhập thiếu tháng trước',
      prefix: 'currency',
    },
    {
      key: 'phat',
      title: 'Phạt',
      extraTime: true,
      logsArray: true,
    },
    {
      key: 'the_ten',
      title: 'Thẻ tên',
      prefix: 'currency',
    },
    {
      key: 'mba',
      title: 'MBA',
      prefix: 'currency',
    },
    {
      key: 'ao_tvv',
      title: 'Áo TVV',
      prefix: 'currency',
    },
    {
      key: 'tong_thu_nhap',
      title: 'Tổng thu nhập',
      prefix: 'currency',
    },
    {
      key: 'tru_khac',
      title: 'Trừ khác',
      prefix: 'currency',
    },
    {
      key: 'salary',
      title: 'Lương thực nhận',
      extraTime: true,
      logsArray: true,
      prefix: 'currency',
    },
  ],
  //Sale Expert config
  'sale-expert': [
    {
      key: 'revenue_personal',
      title: 'Doanh số cá nhân',
      extraTime: true,
      logsArray: true,
      prefix: 'currency',
    },
    {
      key: 'revenue_sale_expert',
      title: 'Doanh số đội SE',
      extraTime: true,
      logsArray: true,
      prefix: 'currency',
    },
    {
      key: 'thu_nhap_kinh_doanh',
      title: 'Thu nhập kinh doanh',
      extraTime: true,
      logsArray: true,
      prefix: 'currency',
    },
    {
      key: 'so_hop_dong',
      title: 'Số hợp đồng TVV',
      bold: true,
    },
    {
      key: 'thu_nhap_tvv',
      title: 'Thu nhập TVV',
      extraTime: true,
      logsArray: true,
      prefix: 'currency',
    },
    {
      key: 'thuong',
      title: 'Thưởng',
      extraTime: true,
      logsArray: true,
      prefix: 'currency',
    },
    {
      key: 'thu_nhap_thieu_thang_truoc',
      title: 'Thu nhập thiếu tháng trước',
      prefix: 'currency',
    },
    {
      key: 'phat',
      title: 'Phạt',
      extraTime: true,
      logsArray: true,
      prefix: 'currency',
    },
    {
      key: 'the_ten',
      title: 'Thẻ tên',
      prefix: 'currency',
    },
    {
      key: 'mba',
      title: 'MBA',
      prefix: 'currency',
    },
    {
      key: 'ao_tvv',
      title: 'Áo TVV',
      prefix: 'currency',
    },
    {
      key: 'tong_thu_nhap',
      title: 'Tổng thu nhập',
      prefix: 'currency',
    },
    {
      key: 'tru_khac',
      title: 'Trừ khác',
      prefix: 'currency',
    },
    {
      key: 'salary',
      title: 'Lương thực nhận',
      extraTime: true,
      logsArray: true,
      prefix: 'currency',
    },
  ],
  //Sale Intern config
  'sale-intern': [
    {
      key: 'revenue_personal',
      title: 'Doanh số cá nhân',
      extraTime: true,
      logsArray: true,
      prefix: 'currency',
    },
    {
      key: 'attendance',
      title: 'Số công tháng',
      extraTime: true,
      logsArray: true,
    },
    {
      key: 'thuong',
      title: 'Thưởng',
      extraTime: true,
      logsArray: true,
      prefix: 'currency',
    },
    {
      key: 'thu_nhap_thieu_thang_truoc',
      title: 'Thu nhập thiếu tháng trước',
      prefix: 'currency',
    },
    {
      key: 'phat',
      title: 'Phạt',
      extraTime: true,
      logsArray: true,
      prefix: 'currency',
    },
    {
      key: 'the_ten',
      title: 'Thẻ tên',
      prefix: 'currency',
    },
    {
      key: 'tong_thu_nhap',
      title: 'Tổng thu nhập',
      prefix: 'currency',
    },
    {
      key: 'tru_khac',
      title: 'Trừ khác',
      prefix: 'currency',
    },
    {
      key: 'salary',
      title: 'Lương thực nhận',
      extraTime: true,
      logsArray: true,
      prefix: 'currency',
    },
  ],
  //Sale member config
  'sale-member': [
    {
      key: 'revenue_personal',
      title: 'Doanh số cá nhân',
      extraTime: true,
      logsArray: true,
      prefix: 'currency',
    },
    {
      key: 'attendance',
      title: 'Số công tháng',
      extraTime: true,
      logsArray: true,
    },
    {
      key: (row) => row.salary + row.thuong - row.phat,
      title: 'Thu nhập',
      logsArray: true,
      prefix: 'currency',
    },
    {
      key: 'thuong',
      title: 'Thưởng',
      extraTime: true,
      logsArray: true,
      prefix: 'currency',
    },
    {
      key: 'thu_nhap_thieu_thang_truoc',
      title: 'Thu nhập thiếu tháng trước',
      prefix: 'currency',
    },
    {
      //foreach $log->phat
      key: 'phat',
      title: 'Phạt',
      extraTime: true,
      logsArray: true,
      prefix: 'currency',
    },
    {
      key: 'the_ten',
      title: 'Thẻ tên',
      prefix: 'currency',
    },
    {
      key: 'tong_thu_nhap',
      title: 'Tổng thu nhập',
      prefix: 'currency',
    },
    {
      key: 'tru_khac',
      title: 'Trừ khác',
      prefix: 'currency',
    },
    {
      //foreach $log->salary
      key: 'salary',
      title: 'Lương thực nhận',
      extraTime: true,
      logsArray: true,
      prefix: 'currency',
    },
  ],
};

//Head office's payslip based on current account type and log account type
export const headOfficeType = {
  logAccountType: new Set(['bod', 'teamleader', 'nhân viên']),
  userAccountType: new Set(['bod', 'teamleader', 'nhân viên','nhan-vien']),
};
//GDVP's payslip based on current account type and log account type
export const gdvpType = {
  logAccountType: new Set(['giám đốc văn phòng thực tập', 'gđvp', 'gđvp tt']),
  userAccountType: new Set(['gdvp', 'gdvp tt', 'gđvp']),
};
//GDKV's payslip based on current account type and log account type
export const gdkvType = {
  logAccountType: new Set(['gdkv', 'gdkv tt', 'gđkv', 'gđkv tt']),
  userAccountType: new Set(['gdkv', 'gdkv tt', 'gđkv', 'gđkv tt']),
};
//QLHV's payslip based on current account type and log account type
export const qlhvType = {
  logAccountType: new Set(['qlhv', 'center_assign', 'Quản Lý Học Viên']),
  userAccountType: new Set(['qlhv', 'center_assign','quan-ly-hoc-vien','quan-ly-hoc-vien']),
};
//Sale Intern's payslip based on current account type and log account type
export const saleInternType = {
  logAccountType: new Set(['sales intern', 'sale intern', 'sales thử việc']),
  userAccountType: new Set(['sales intern','quan-ly-hoc-vien']),
};
//Sale Member's payslip based on current account type and log account type
export const saleMemberType = {
  logAccountType: new Set(['sale member', 'sales']),
  userAccountType: new Set(['sale member', 'sales','quan-ly-hoc-vien']),
};
//Sale Expert's payslip based on current account type and log account type
export const saleExpertType = {
  logAccountType: new Set(['sale expert']),
  userAccountType: new Set(['sale expert', 'sales leader', 'sales expert','quan-ly-hoc-vien']),
};
//Sale Leader's payslip based on current account type and log account type
export const saleLeaderType = {
  logAccountType: new Set(['sales leader']),
  userAccountType: new Set(['sale', 'sales leader','quan-ly-hoc-vien']),
};
const DANG_TICH_LUY = 0;
const CO_HIEU_LUC = 1;
const DUOC_CHUYEN_NHUONG_BAN = 2;
const DA_CHUYEN_NHUONG_BAN = 3;
export const NAME_PHASE = {
  0: 'Đang tích lũy',
  1: 'Có hiệu lực',
  2: 'Được chuyển nhượng/ Bán',
  3: 'Đã chuyển nhượng/ Bán',
}