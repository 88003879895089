export const PAYSLIP_TEACHER_TABLE = [
    {type: '', label: 'Các khoản thu nhập',key:'',bold:true},
    {type: '1', label: 'Thu nhập dạy học (1)',key:'teaching_income',isDetail:true},
    {type: '', label: 'Trả cọc (2)',key:'deposit_payment'},
    {type: '3', label: 'Thu nhập khác (3)',key:'other_income',isDetail:true},
    {type: '4', label: 'Thưởng (4)',key:'bonus',isDetail:true},
    {type: '', label: 'Tổng các khoản thu nhập (5)',key:'total_income'},
    {type: '10', label: 'Giá trị trái phiếu tích lũy',key:'bond_value',isDetail:true},
    {type: '', label: '',key:'',last_step:true},
    {type: '', label: 'Các khoản trừ thu nhập',key:'',bold:true},
    {type: '', label: 'Thu cọc (6)',key:'deposit_collection'},
    {type: '7', label: 'Phạt (7)',key:'punish',isDetail:true},
    {type: '8', label: 'Trích thuế thu nhập cá nhân (8)',key:'personal_income_tax'},
    {type: '', label: 'Tổng các khoản trừ thu nhập (9)',key:'total_income_deduction'},
    {type: '', label: '',key:'',last_step:true},
    {type: '', label: 'Thu nhập thực nhận (10) = (5) - (9)',key:'real_income_receive',bold:true},
];